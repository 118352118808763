

export default [
  {
    path: '/advanced-settings',
    meta: { label: 'General Settings' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'advanced-settings',
        meta: {
          label: 'Advanced Settings',
          permission: 'show_all_advanced'
        },
        component: () => import('./../../views/settings/index'),
      },
      {
        path: 'create',
        meta: { label: 'Advanced Setting Create', permission: 'create_advanced' },
        name: 'CreateAdvancedSetting',
        component: () => import('./../../views/settings/create'),
      },
      {
        path: ':id',
        meta: { label: 'Advanced Setting Details', permission: 'show_single_advanced' },
        name: 'AdvancedSetting',
        component: () => import('./../../views/settings/show'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Advanced Setting', permission: 'edit_advanced' },
        name: 'EditAdvancedSetting',
        component: () => import('./../../views/settings/edit')
      },

    ]
  },
  {
    path: '/scheduled-settings',
    meta: { label: 'Scheduled Settings' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'scheduled-settings',
        meta: {
          label: 'Scheduled Settings',
          permission: 'show_all_scheduled'
        },
        component: () => import('./../../views/scheduledsettings/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Scheduled Setting Create', permission: 'create_scheduled' },
        name: 'CreateScheduled',
        component: () => import('./../../views/scheduledsettings/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Scheduled Setting Details', permission: 'show_single_scheduled' },
        name: 'Scheduled',
        component: () => import('./../../views/scheduledsettings/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Scheduled Setting', permission: 'edit_scheduled' },
        name: 'EditScheduled',
        component: () => import('./../../views/scheduledsettings/edit.vue')
      },

    ]
  },
  {
    path: '/company',
    meta: { label: 'Company Details' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'company',
        meta: { permission: 'show_all_company_details' },
        component: () => import('./../../views/company/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Company Details Create', permission: 'create_company_details' },
        name: 'CreateCompany',
        component: () => import('./../../views/company/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Company Details', permission: 'show_company_details' },
        name: 'Company',
        component: () => import('./../../views/company/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Company Details', permission: 'edit_company_details' },
        name: 'EditCompany',
        component: () => import('./../../views/company/edit.vue')
      },
    ]
  },

  {
    path: '/leaderboards',
    meta: { label: 'leaderboards Details' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'leaderboards',
        meta: { permission: 'show_all_leaderboards_details' },
        component: () => import('./../../views/leaderboards/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'leaderboards Create', permission: 'create_leaderboards' },
        name: 'CreateLeaderboards',
        component: () => import('./../../views/leaderboards/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Leaderboards Details', permission: 'show_leaderboards_details' },
        name: 'Leaderboard',
        component: () => import('./../../views/leaderboards/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Leaderboards Details', permission: 'edit_leaderboards_details' },
        name: 'EditLeaderboards',
        component: () => import('./../../views/leaderboards/edit.vue')
      },
    ]
  },
  {
    path: '/alerts',
    meta: { label: 'Alerts Details' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'alerts',
        label: 'Alerts',
        meta: { permission: 'show_all_alerts_details' },
        component: () => import('./../../views/alerts/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Alerts Create', permission: 'create_alerts' },
        name: 'CreateAlerts',
        component: () => import('./../../views/alerts/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Alerts Details', permission: 'show_alerts_details' },
        name: 'Alert',
        component: () => import('./../../views/alerts/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Alerts Details', permission: 'edit_alerts_details' },
        name: 'EditAlerts',
        component: () => import('./../../views/alerts/edit.vue')
      },
    ]
  },

  {
    path: '/errormessages',
    meta: { label: 'Error Messages' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { label: 'Error Messages', permission: 'show_all_error_messages' },
        name: 'error-messages',
        component: () => import('./../../views/errormessages/index.vue'),
      },
      {
        path: 'create',
        meta: { label: ' Create', permission: 'create_error_messages' },
        name: 'CreateErrorMessage',
        component: () => import('./../../views/errormessages/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Error message Details', permission: 'show_single_error_messages' },
        name: 'ErrorMessage',
        component: () => import('./../../views/errormessages/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Error Message', permission: 'edit_error_messages' },
        name: 'EditErrorMessage',
        component: () => import('./../../views/errormessages/edit.vue'),
      },
    ]
  },
  {
    path: '/off-days',
    meta: { label: 'Off Days' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'OffDays',
        meta: { permission: 'show_all_off_days' },
        component: () => import('./../../views/offDays/index.vue'),
      },
      {
        path: 'create',
        meta: { label: ' Create Off Day', permission: 'create_off_days' },
        name: 'CreateOffDay',
        component: () => import('./../../views/offDays/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Notification Center Details', permission: 'show_single_off_days' },
        name: 'OffDay',
        component: () => import('./../../views/offDays/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Notification Center', permission: 'edit_off_days' },
        name: 'EditOffDay',
        component: () => import('./../../views/offDays/edit.vue')
      },
    ]
  },
  {
    path: '/socials',
    meta: { label: 'Socials' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'socials',
        meta: { permission: 'show_all_socials' },
        component: () => import('./../../views/socials/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Social Create', permission: 'create_socials' },
        name: 'CreateSocial',
        component: () => import('./../../views/socials/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Social Details', permission: 'show_single_socials' },
        name: 'Social',
        component: () => import('./../../views/socials/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Social', permission: 'edit_socials' },
        name: 'EditSocial',
        component: () => import('./../../views/socials/edit.vue')
      },
    ]
  },
  {
    path: '/notification_center',
    meta: { label: 'Notification Center' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'notificationCenters',
        meta: { permission: 'show_all_notification_center' },
        component: () => import('./../../views/notificationcenter/index.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Notification Center Details', permission: 'show_single_notification_center' },
        name: 'NotificationCenter',
        component: () => import('./../../views/notificationcenter/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Notification Center', permission: 'edit_notification_center' },
        name: 'EditNotificationCenter',
        component: () => import('./../../views/notificationcenter/edit.vue')
      },
    ]
  },
  {
    path: '/kpis',
    meta: { label: 'KPIs' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_kpis' },
        name: 'kpis-setting',
        component: () =>
          import('./../../views/kpisSettings/index.vue'),
      },
      {
        path: ':id/edit',
        meta: { permission: 'create_kpis' },
        name: 'EditKpis',
        component: () =>
          import('./../../views/kpisSettings/edit.vue'),
      }
    ]
  },
  {
    path: '/field-days-kpi',
    meta: { label: 'Field Days' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_field_days' },
        name: 'field-days-kpi',
        component: () =>
          import('./../../views/fieldDays/index.vue'),
      },
      {
        path: 'create',
        meta: { permission: 'create_field_days' },
        name: 'CreateFieldDays',
        component: () =>
          import('./../../views/fieldDays/create.vue'),
      }
    ]
  },
  {
    path: '/policies',
    meta: { label: 'Policies' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { permission: 'show_all_policies' },
        name: 'policies-setting',
        component: () =>
          import('./../../views/policies/index.vue'),
      },
      {
        path: 'create',
        meta: { permission: 'create_policies' },
        name: 'CreatePolicy',
        component: () =>
          import('./../../views/policies/create.vue'),
      },
      {
        path: ":id",
        name: "ShowPolicy",
        meta: {
          label: "Show Category",
          permission: "show_single_policies"
        },
        component: () =>
          import("./../../views/policies/show.vue")
      },
      {
        path: ":id/edit",
        name: "EditPolicy",
        meta: {
          label: "Edit Category",
          permission: "edit_policies"
        },
        component: () =>
          import("./../../views/policies/edit.vue")
      }
    ]
  },
  {
    path: '/power-bi',
    meta: { label: 'Power Bi Setting' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [{
      path: '',
      meta: { permission: 'show_all_power_bi' },
      name: 'power-bi',
      component: () =>
        import('./../../views/powerbisetting/index.vue'),
    },
    {
      path: 'create',
      meta: { label: 'Power Bi Create', permission: 'create_power_bi' },
      name: 'CreatePowerBi',
      component: () => import('./../../views/powerbisetting/create.vue'),
    },
    {
      path: ':id',
      meta: { label: 'Edit Power Bi Setting', permission: 'edit_power_bi' },
      name: 'EditPowerBi',
      component: () => import('./../../views/powerbisetting/edit.vue'),
    },]
  },
  {
    path: '/linked-pharmacies-settings',
    meta: { label: 'Linked Pharmacies Details' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'linked-pharmacies-settings',
        meta: { permission: 'show_all_linked_pharmacies_settings' },
        component: () => import('./../../views/linkedpharmaciessettings/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Create Linked Pharmacy Setting', permission: 'create_linked_pharmacies_settings' },
        name: 'CreateLinkedPharmacySetting',
        component: () => import('./../../views/linkedpharmaciessettings/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Linked Pharmacy Details', permission: 'show_single_linked_pharmacies_settings' },
        name: 'LinkedPharmacySetting',
        component: () => import('./../../views/linkedpharmaciessettings/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Linked Pharmacy Setting', permission: 'edit_linked_pharmacies_settings' },
        name: 'EditLinkedPharmacySetting',
        component: () => import('./../../views/linkedpharmaciessettings/edit.vue')
      },
    ]
  },
  {
    path: '/linked-pharmacies',
    meta: { label: 'Linked Pharmacies' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'linked-pharmacies',
        meta: { permission: 'show_all_linked_pharmacies_to_doctors' },
        component: () => import('./../../views/linkedpharmaciestodoctors/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Create Linked Pharmacy', permission: 'create_linked_pharmacies' },
        name: 'linked-pharmacies-to-accounts',
        component: () => import('./../../views/linkedpharmaciestodoctors/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Linked Pharmacy Details', permission: 'show_single_linked_pharmacies' },
        name: 'linked-pharmacy',
        component: () => import('./../../views/linkedpharmaciestodoctors/show.vue'),
      },
    ]
  },
  {
    path: '/linked-pharmacies-per-brand',
    meta: { label: 'Linked Pharmacies Per Brand' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: '',
        meta: { permission: 'create_linked_pharmacies_per_brand' },
        component: () => import('./../../views/linkedPharmaciesPerBrand/create.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Create Linked Pharmacy', permission: 'create_linked_pharmacies' },
        name: 'linked-pharmacies-per-brand',
        component: () => import('./../../views/linkedpharmaciestodoctors/create.vue'),
      },
    ]
  },
];
